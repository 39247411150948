import React, { Suspense, lazy } from "react";
import { Route, Switch, Router } from "react-router-dom";
import history from "../history";
import { EditorProvider } from "../context/EditorContext";
import { ApplicationProvider } from "../context/ApplicationContext";

const Landing = lazy(() => import('./Landing'));
const WorkFeed = lazy(() => import('./WorkFeed'))
const WorkViewer = lazy(() => import('./WorkViewer'))
const NotFound = lazy(() => import('./NotFound'))
const Contact = lazy(() => import('./Contact'))

const App = () => {
  return (
    <div>
      <Router history={history}>
        <Suspense fallback={
          <div className="text-center">
            <div
              className="spinner"
              style={{ display: "inline" }}>
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          </div>
        }>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/work/:id" component={WorkViewer} />
            <Route exact path="/work" component={WorkFeed} />
            <Route exact path="/work/page/:page" component={WorkFeed} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};
export default function AppWrapper() {
  return (
    <ApplicationProvider>
      <EditorProvider>
        <App />
      </EditorProvider>
    </ApplicationProvider>
  );
}
