import {
  CREATE_NEW_PROJECT_PLAN,
  API_CALL_START,
  API_CALL_END_SUCCESS,
  API_CALL_END
} from "../actions/types"

export default (state = {}, action) => {
  switch (action.type) {
  case CREATE_NEW_PROJECT_PLAN:
    console.log(action.payload)
    return {...state, response: action.payload}
  case API_CALL_START:
    return {
      ...state, isProcessRunning: true, isProcessSuccessful: false
    }
  case API_CALL_END:
    return {
      ...state, isProcessRunning: false, isProcessSuccessful: false
    }
  case API_CALL_END_SUCCESS:
    return {
      ...state, isProcessRunning: false, isProcessSuccessful: true
    }
  default:
    return state
  }
}