import {NEW_TOAST_GOOD, NEW_TOAST_BAD, CLEAR_EXISTING_TOAST} from "../actions/types"

export default function (state = {}, action) {
    switch (action.type) {
        case NEW_TOAST_GOOD:
            return {...state, message: action.payload, kind: "ok"}
        case NEW_TOAST_BAD:
            return {...state, message: action.payload, kind: "err"}
        case CLEAR_EXISTING_TOAST:
            return {...state, message: "", kind: ""}
        default:
            return state
    }
}