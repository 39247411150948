import React, {useState} from "react"

export const EditorContext = React.createContext({
  title: "",
  setTitle: () => "",
  subHeading: "",
  setSubHeading: () => "",
  tags: "",
  setTags: () => "",
  type: "",
  setType: () => "",
  clientDomain: "",
  setClientDomain: () => "",
  roles: "",
  setRoles: () => "",
  status: "",
  setStatus: () => "",
  projectPublic: "",
  setProjectPublic: () => "",  
  projectCost: "",
  setProjectCost: () => 0,
  projectUniqueId: "",
  setProjectUniqueId: () => 0,
  projectSection: [],
  setProjectSection: () => [],
  projectImages: [],
  setProjectImages: () => []
})

export function EditorProvider({children}) {

  const [title, setTitle] = useState("")
  const [projectSection, setProjectSection] = useState([])
  const [projectImages, setProjectImages] = useState([])
  const [subHeading, setSubHeading] = useState("")
  const [tags, setTags] = useState("")
  const [type, setType] = useState("")
  const [clientDomain, setClientDomain] = useState("")
  const [roles, setRoles] = useState("")
  const [status, setStatus] = useState("")
  const [projectPublic, setProjectPublic] = useState("")
  const [projectCost, setProjectCost] = useState(0)
  const [projectUniqueId, setProjectUniqueId] = useState(0)
  const [mainEditorState, setMainEditorState] = useState(null)


  return (
    <EditorContext.Provider
      value={{
        title,
        setTitle,
        subHeading,
        setSubHeading,
        tags,
        setTags,
        type,
        setType,
        clientDomain,
        setClientDomain,
        roles,
        setRoles,
        status,
        setStatus,
        projectPublic,
        setProjectPublic,
        projectCost,
        setProjectCost,
        projectUniqueId,
        setProjectUniqueId,
        projectSection,
        setProjectSection,
        projectImages,
        setProjectImages,
        mainEditorState,
        setMainEditorState
      }}>
      {children}
    </EditorContext.Provider>
  )
}