import React, {useState} from "react"

export const ApplicationContext = React.createContext({
  title: "",
  setTitle: () => undefined,
  username: "",
  setUsername: () => undefined,
  loggedIn: false,
  isLoggedIn: () => undefined,
  authToken: "",
  setAuthToken: () => undefined,
  userRights: {},
  setUserRights: () => undefined,
})

export function ApplicationProvider({children}) {

  const [title, setTitle] = useState("")
  const [authToken, setAuthToken] = useState("")
  const [username, setUsername] = useState("")
  const [loggedIn, isLoggedIn] = useState(false)
  const [userRights, setUserRights] = useState({})

  return (
    <ApplicationContext.Provider
      value={{
        title,
        setTitle,
        loggedIn,
        isLoggedIn,
        username,
        setUsername,
        authToken,
        setAuthToken,
        userRights,
        setUserRights
      }}>
      {children}
    </ApplicationContext.Provider>
  )
}