import {combineReducers} from "redux"
import {reducer as formReducer} from "redux-form"
import projectPlannerReducer from "./projectPlannerReducer"
import toastReducer from "./toastReducer"

export default combineReducers({
    form: formReducer,
    planner: projectPlannerReducer,
    toasts: toastReducer
})
