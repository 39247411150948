import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "./store"
import App from "./pages/App"
import * as serviceWorker from "./serviceWorker"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import $ from "jquery/dist/jquery.min"


window.jQuery = $
window.$ = $
global.jQuery = $

ReactDOM.render(
<Provider store={store}>
  <App/>
</Provider>, document.getElementById("root"))
serviceWorker.unregister()
